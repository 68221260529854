import React from 'react'

import { Pages } from '../enums'

import siteData from '../content/data.json'

import DefaultLayout from '../templates/DefaultLayout'
import Section from '../components/Section'
import Container from '../components/Container'
import Paragraph from '../components/Paragraph'
import Usps from '../components/Usps'
import Gallery from '../components/Gallery'
import Addresses from '../components/Addresses'
import Hint from '../components/Hint'

import '../scss/styles.scss'

const MiezeNova = () => {
  return (
    <DefaultLayout
      title={siteData.miezeNova.title}
      description={siteData.miezeNova.description}
      activePage={Pages.MIEZE_NOVA}
    >
      <Section>
        <Container>
          <h1 className="title">{siteData.miezeNova.headline}</h1>
          <Paragraph className="protection">
            {siteData.miezeNova.protection}
          </Paragraph>
        </Container>
      </Section>

      <Usps
        imageSrc={`../${siteData.miezeNova.uspImg}`}
        usps={siteData.miezeNova.usps}
      />

      <Section>
        <Container>
          <Paragraph>
            <strong>„Mieze Nova“ (S)</strong> ist eine Gourmetfrucht mit
            hervorragendem Geschmack und einem feinen Erdbeeraroma wie die
            Muttersorte „Mieze Schindler“.
            <br />
            <br />
            Ihr Vorteil: <br />
            Sie hat festere und größere Früchte als „Mieze Schindler“ und einen
            höheren Ertrag.{' '}
          </Paragraph>
          <Paragraph>
            <strong>„Mieze Nova“ (S)</strong> überzeugt durch eine sehr
            attraktive, schön geformte und stark glänzende Frucht. Die
            Fruchtfarbe ist ein sehr ansprechendes kräftiges Rot.
            <br />
            <br />
            Man kann die Frucht auch etwas länger an der Pflanze reifen lassen,
            ohne dass sie fault. Sie wird dann dunkelrot und nimmt die typische
            Farbe der Muttersorte „Mieze Schindler“ an.
          </Paragraph>
          <Paragraph>
            Im Gegensatz zu „Mieze Schindler“ mit ihren tief eingesenkten
            Kelchblättern sitzt bei „Mieze Nova“(S) der Kelch obenauf und lässt
            sich leicht entfernen.
          </Paragraph>
          <Paragraph>
            <strong>„Mieze Nova“ (S)</strong> ist sehr gesund und
            widerstandsfähig gegen Mehltau und Verticillium.
            <br />
            <br />
            Durch ihr dickes Blatt übersteht sie Trockenperioden gut.
          </Paragraph>
          <Paragraph>
            <strong>„Mieze Nova“ (S)</strong> ist im Gegensatz zu ihrer
            Muttersorte „Mieze Schindler“ selbstfruchtbar und benötigt keine
            Bestäubersorte. Sie kann z. B. auch ohne andere Erbeersorten im
            Garten oder auch im Balkonkasten kultiviert werden.
          </Paragraph>
          <Paragraph className="is-last">
            Nach der Haupternte im Juni blühen die in der Zeit an der
            Mutterpflanze gebildeten neuen Ausläuferpflanzen sofort und bilden
            nach der eigentlichen Erdbeersaison noch im Juli und August Früchte
            aus, eine köstliche Nachernte zum Naschen und für den Eisbecher.
          </Paragraph>
        </Container>
      </Section>

      <Section>
        <Container>
          <Gallery images={siteData.miezeNova.images} />
        </Container>
      </Section>

      <Hint hint={siteData.miezeNova.hints[0]} />

      <Addresses
        title={siteData.miezeNova.mailOrderTitle}
        type="mailOrder"
        addresses={siteData.miezeNova.mailOrder}
      />

      <Hint
        hint={siteData.miezeNova.hints[1]}
        hintUrl={siteData.miezeNova.hintUrl.url}
      />

      <Addresses
        title={siteData.miezeNova.licenseesTitle}
        type="licensees"
        addresses={siteData.miezeNova.licensees}
      />
    </DefaultLayout>
  )
}

export default React.memo(MiezeNova)
